import { getLast } from '../../core/utils/common-utils';
import getTileSize from './get-tile-size';
import Tile from '../../components/domain/Tile/Tile';
import { getIsCurrentGameCompleted } from '../../store/selectors';

const redoAction = state => {
  if (getIsCurrentGameCompleted({ game: { model: state } })) {
    return state;
  }

  const { actions, canceledActions, cellsInRow } = state;
  const size = getTileSize(cellsInRow);

  if (!canceledActions.length) {
    return state;
  }

  const undoAction = getLast(canceledActions);

  return {
    ...state,
    actions: [...actions, undoAction],
    canceledActions: canceledActions.slice(0, canceledActions.length - 1),
    score: undoAction.score,
    tiles: undoAction.tiles.map(props => new Tile({ ...props, size })),
  };
};

export default redoAction;
