export const AC_CHANGE_CELLS_IN_ROW = 'AC_CHANGE_CELLS_IN_ROW';
export const AC_RESTART_GAME = 'AC_RESTART_GAME';

export const AC_CREATE_TILE = 'AC_CREATE_TILE';
export const AC_REFRESH_TILES = 'AC_REFRESH_TILES';

export const AC_TILES_TO_LEFT = 'AC_TILES_TO_LEFT';
export const AC_TILES_TO_RIGHT = 'AC_TILES_TO_RIGHT';
export const AC_TILES_TO_UP = 'AC_TILES_TO_UP';
export const AC_TILES_TO_DOWN = 'AC_TILES_TO_DOWN';
export const AC_UNDO_ACTION = 'AC_UNDO_ACTION';
export const AC_REDO_ACTION = 'AC_REDO_ACTION';

export const AC_SET_GAME_STATE = 'AC_SET_GAME_STATE';

// export const AC_ENTITY_SHOULD_BE_LOADED = 'AC_ENTITY_SHOULD_BE_LOADED';
export const AC_ENTITY_LOADING_START = 'AC_ENTITY_LOADING_START';
export const AC_ENTITY_LOADING_ERROR = 'AC_ENTITY_LOADING_ERROR';
export const AC_ENTITY_LOADING_SUCCESS = 'AC_ENTITY_LOADING_SUCCESS';
export const AC_ENTITY_SAVE_START = 'AC_ENTITY_SAVE_START';
export const AC_ENTITY_SAVE_ERROR = 'AC_ENTITY_SAVE_ERROR';
export const AC_ENTITY_SAVE_SUCCESS = 'AC_ENTITY_SAVE_SUCCESS';
