import React from 'react';

import Field from './shared/Field';
import FieldService from '../services/FieldService';
import FieldController from '../Controller';
// import NetworkService from '../services/NetworkService';
import TopGames from '../modals/TopGames';
import GameLayout from '../modals/GameLayout';
import GameIsOver from '../modals/GameIsOver';

const isDevelopment = process.env.NODE_ENV === 'development';

const isTestOnProd = true;

const MainContent = () => {
  return isDevelopment || isTestOnProd ? (
    <>
      <Field />
      <FieldService />
      <FieldController />
      {/* <NetworkService /> */}
      <TopGames />
      <GameLayout />
      <GameIsOver />
    </>
  ) : (
    'The game is under development now'
  );
};

export default MainContent;
