import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import Form from '../../../../../core/components/Form';
import FormFieldset from '../../../../../core/components/Form/shared/FormFieldset';
import Legend from '../../../../../core/components/Form/shared/Legend';
import Tile from '../../../../domain/Tile/Tile';
import getTileSize from '../../../../../utils/store/get-tile-size';
import { FIELD } from '../../../../../constants';

import styles from './GameLayoutForm.module.scss';

const { CANVAS_SIZE } = FIELD;

const GameLayoutForm = ({ matrix, closeModal }) => {
  const field = useRef();

  useEffect(() => {
    const tiles = matrix.map((value, i) => {
      const cellsInRow = Math.sqrt(matrix.length);
      const size = getTileSize(cellsInRow);

      const x = i % cellsInRow;
      const y = Math.floor(i / cellsInRow);

      return new Tile({ value, x, y, size });
    });
    const ctx = field.current.getContext('2d');
    tiles.forEach(tile => {
      tile.render(ctx);
    });
  }, [matrix]);

  return (
    <Form
      className={styles['game-layout-form']}
      name="gameLayoutForm"
      withCross
      onCrossClick={closeModal}
    >
      <FormFieldset className={styles['game-layout-form-fieldset']}>
        <Legend className={styles['game-layout-form-fieldset__title']}>
          Game Layout
        </Legend>
        <section className={styles['game-layout-form-fieldset__content']}>
          <canvas
            ref={field}
            className={styles.field}
            width={CANVAS_SIZE}
            height={CANVAS_SIZE}
          />
        </section>
      </FormFieldset>
    </Form>
  );
};

GameLayoutForm.propTypes = {
  matrix: PropTypes.arrayOf(PropTypes.number),
  closeModal: PropTypes.func,
};

GameLayoutForm.defaultProps = {
  matrix: [],
  closeModal: () => {},
};

export default GameLayoutForm;
