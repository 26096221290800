import roundedRect from '../../../core/utils/canvas/rounded-rect';
import { TILE } from '../../../constants';
import getColorByValue from '../../../utils/store/get-color-by-value';

const { SPEED } = TILE;

class Tile {
  constructor(props = {}) {
    this.props = {
      value: props.value || 0,
      x: props.x || 0,
      y: props.y || 0,
      defaultSize: props.size || 1,
    };

    this.props.targetValue = this.props.value;
    this.props.fieldX = this.props.x * this.props.defaultSize;
    this.props.fieldY = this.props.y * this.props.defaultSize;
    this.props.targetFieldX = this.props.fieldX;
    this.props.targetFieldY = this.props.fieldY;
    this.props.shadowFieldX = this.props.fieldX;
    this.props.shadowFieldY = this.props.fieldY;
    this.props.size = this.props.defaultSize;
    this.props.targetSize = this.props.defaultSize + 4;
    this.props.color = getColorByValue(this.props.value);

    this.setProps = this.setProps.bind(this);
    this.getFieldCoordinate = this.getFieldCoordinate.bind(this);
    this.getIsMovingOnly = this.getIsMovingOnly.bind(this);
    this.getIsMerging = this.getIsMerging.bind(this);
    this.getIsMoving = this.getIsMoving.bind(this);
    this.moveXY = this.moveXY.bind(this);
    this.merge = this.merge.bind(this);
    this.calculate = this.calculate.bind(this);
    this.draw = this.draw.bind(this);
    this.render = this.render.bind(this);
    this.update = this.update.bind(this);
  }

  setProps(newProps = {}) {
    this.props = { ...this.props, ...newProps };
  }

  getFieldCoordinate(c) {
    const { defaultSize } = this.props;

    return c * defaultSize;
  }

  getIsMovingOnly() {
    const { fieldX, fieldY, targetFieldX, targetFieldY } = this.props;

    return fieldX !== targetFieldX || fieldY !== targetFieldY;
  }

  getIsMerging() {
    const {
      shadowFieldX,
      shadowFieldY,
      targetFieldX,
      targetFieldY,
    } = this.props;

    return shadowFieldX !== targetFieldX || shadowFieldY !== targetFieldY;
  }

  getIsScaling() {
    const { defaultSize, size, targetSize } = this.props;

    return size !== targetSize || targetSize !== defaultSize;
  }

  getIsMoving() {
    return this.getIsMovingOnly() || this.getIsMerging() || this.getIsScaling();
  }

  moveXY(newX, newY) {
    const { x, y } = this.props;
    const isHorizontal = x !== newX;
    const isVertical = y !== newY;
    const isMoving = isHorizontal || isVertical;

    if (isMoving) {
      const key = isHorizontal ? 'x' : 'y';
      const ucKey = key.toUpperCase();
      const targetFieldKey = `targetField${ucKey}`;
      const shadowFieldKey = `shadowField${ucKey}`;
      this.props[key] = isHorizontal ? newX : newY;
      this.props[targetFieldKey] = this.getFieldCoordinate(this.props[key]);
      if (this.props.targetValue === this.props.value) {
        this.props[shadowFieldKey] = this.props[targetFieldKey];
      }
    }
  }

  merge(tile) {
    this.setProps({
      targetValue: this.props.value * 2,
      targetSize: this.props.defaultSize + 4,
      shadowFieldX: tile.props.fieldX,
      shadowFieldY: tile.props.fieldY,
    });
  }

  getDirection = (current, target) => {
    if (current === target) {
      return 0;
    }
    const delta = target - current;

    return delta / Math.abs(delta);
  };

  move = (current, target, speed = SPEED) => {
    const direction = this.getDirection(current, target);
    const newCurrent = current + direction * speed;
    const newDirection = this.getDirection(newCurrent, target);

    return direction === newDirection ? newCurrent : target;
  };

  calculate() {
    const {
      fieldX,
      fieldY,
      defaultSize,
      size,
      targetFieldX,
      targetFieldY,
      shadowFieldX,
      shadowFieldY,
      targetSize,
      value,
      targetValue,
    } = this.props;
    const isMoving = this.getIsMovingOnly();
    const isMerging = this.getIsMerging();
    const isScaling = !(isMoving || isMerging) && this.getIsScaling();

    if (isMoving) {
      this.props.fieldX = this.move(fieldX, targetFieldX);
      this.props.fieldY = this.move(fieldY, targetFieldY);
    }
    if (isMerging) {
      this.props.shadowFieldX = this.move(shadowFieldX, targetFieldX);
      this.props.shadowFieldY = this.move(shadowFieldY, targetFieldY);
    }
    if (isScaling) {
      if (targetValue !== value) {
        this.props.value = targetValue;
        this.props.color = getColorByValue(targetValue);
      }
      this.props.size = this.move(size, targetSize, 1);
      if (
        this.props.size === targetSize &&
        this.props.targetSize !== defaultSize
      ) {
        this.props.targetSize = defaultSize;
      }
    }
  }

  draw(context, drawX, drawY) {
    const { size, color } = this.props;
    const halfSize = size / 2;
    const cx = drawX + halfSize;
    const cy = drawY + halfSize;
    context.strokeStyle = color;
    context.fillStyle = color;
    roundedRect(context, cx, cy, size - 4, size - 4, 8, true);
    context.textAlign = 'center';
    context.textBaseline = 'middle';
    context.font = `bold ${this.props.size / 4}px sans-serif`;
    context.fillStyle = 'rgba(255, 255, 255, 1)';
    context.fillText(`${this.props.value}`, cx, cy);
  }

  render(context) {
    const {
      fieldX,
      fieldY,
      shadowFieldX,
      shadowFieldY,
      targetFieldX,
      targetFieldY,
    } = this.props;

    this.draw(context, fieldX, fieldY);

    if (shadowFieldX !== targetFieldX || shadowFieldY !== targetFieldY) {
      this.draw(context, shadowFieldX, shadowFieldY);
    }
  }

  update(context) {
    this.calculate();
    this.render(context);
  }
}

export default Tile;
