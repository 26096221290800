import { ROUTES } from './constants';
import api from './api';
import reducer from './store/reducers'
import getMainMenuOptions from './components/core-shared/main-menu-options';
import GAME_2048 from './components/MainContent';
import { acChangeCellsInRow, saveCurrentGame, quickSaveCurrentGame, quickLoadCurrentGame } from './store/actions';
import { getIsCurrentGameCompleted, loadStatusSelectorsList, saveStatusSelectorsList } from './store/selectors';

export default {
  api,
  reducer,
  routes: ROUTES,
  loadStatusSelectorsList,
  saveStatusSelectorsList,
  getMainMenuProps: state => ({
    isCurrentGameCompleted: getIsCurrentGameCompleted(state),
  }),
  mainMenuActions: {
    setCellsInRow: acChangeCellsInRow,
    saveCurrentGame,
    quickSaveCurrentGame,
    quickLoadCurrentGame,
  },
  getMainMenuOptions,
  contents: { GAME_2048 },
};
