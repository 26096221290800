import { useEffect } from 'react';
import PropTypes from 'prop-types';

const FieldService = ({ shouldAddTile, isMoving, createTile }) => {
  useEffect(() => {
    if (shouldAddTile && !isMoving) {
      createTile();
    }
  }, [createTile, shouldAddTile, isMoving]);

  return null;
};

FieldService.propTypes = {
  cellsInRow: PropTypes.number,
  shouldAddTile: PropTypes.bool,
  isMoving: PropTypes.bool,
  tiles: PropTypes.arrayOf(PropTypes.shape({})),
  createTile: PropTypes.func,
};

FieldService.defaultProps = {
  cellsInRow: 0,
  shouldAddTile: false,
  isMoving: false,
  tiles: [],
  createTile: () => {},
};

export default FieldService;
