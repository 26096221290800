import { isFilledArray } from '../../core/utils/common-utils';
import { getIsCurrentGameCompleted } from '../../store/selectors';

const getRow = (tiles, line, direction, isHorizontal) => {
  const lineCoordinate = isHorizontal ? 'y' : 'x';
  const colCoordinate = isHorizontal ? 'x' : 'y';

  return tiles
    .filter(({ props: { [lineCoordinate]: tileLine } }) => tileLine === line)
    .sort((tile1, tile2) => {
      const col1 = tile1.props[colCoordinate];
      const col2 = tile2.props[colCoordinate];
      return direction < 0 ? col1 - col2 : col2 - col1;
    });
};

const moveTilesValue = (state, directionX, directionY) => {
  if (getIsCurrentGameCompleted({ game: { model: state } })) {
    return state;
  }

  const { tiles, cellsInRow, score } = state;
  const isMoving = tiles.some(tile => tile.getIsMoving() === true);
  const isHorizontal = directionY === 0;
  const direction = isHorizontal ? directionX : directionY;

  let newScore = score;

  if (isMoving && !direction) {
    return state;
  }

  const colKey = isHorizontal ? 'x' : 'y';
  let shouldAddTile = false;
  let newTiles = [];

  // line-by-line
  for (let lineIndex = 0; lineIndex < cellsInRow; lineIndex++) {
    let row = getRow(tiles, lineIndex, direction, isHorizontal);
    let edge = direction < 0 ? 0 : cellsInRow - 1;

    // tile-by-tile in line
    for (let i = 0; i < row.length; i++) {
      const tile = row[i];
      const { [colKey]: tileCol, value } = tile.props;
      const previousTile = i > 0 ? row[i - 1] : null;

      const isTileMerging = previousTile?.props.value === value;
      const isTileMoving =
        (direction < 0 && tileCol > edge) || (direction > 0 && tileCol < edge);

      if (isTileMerging) {
        previousTile.merge(tile);
        newScore += tile.props.value * 2;
        row[i] = null;
        // console.log('i: before', i);
        // i -= 1;
        // console.log('i: after', i);
        shouldAddTile = true;
      } else if (isTileMoving) {
        const tileX = isHorizontal ? edge : lineIndex;
        const tileY = isHorizontal ? lineIndex : edge;
        tile.moveXY(tileX, tileY);
        edge -= direction;
        shouldAddTile = true;
      } else {
        edge -= direction;
      }
    }
    row = row.filter(Boolean);
    newTiles = newTiles.concat(row);
  }

  return {
    ...state,
    score: newScore,
    shouldAddTile,
    tiles: isFilledArray(newTiles) ? newTiles : tiles,
  };
};

export default moveTilesValue;
