import { createModalsListItemSelector } from '../../core/store/selectors';
import { MODALS_ID } from '../../constants';

export const getTopGamesModalsItem = createModalsListItemSelector(
  MODALS_ID.TOP_GAMES,
);

export const getTopGamesModalShouldBeLoaded = state =>
  getTopGamesModalsItem(state)?.isLoaded === false;

export const getGameLayoutModalsItem = createModalsListItemSelector(
  MODALS_ID.GAME_LAYOUT,
);

export const getGameLayoutMatrix = state =>
  getGameLayoutModalsItem(state)?.matrix || [];
