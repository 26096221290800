import React from 'react';

import Modal from '../../../core/components/Modal';
import TopGamesForm from './shared/TopGamesForm';
import { MODALS_ID } from '../../../constants';

import styles from './TopGames.module.scss';

const TopGames = () => {
  return (
    <Modal
      id={MODALS_ID.TOP_GAMES}
      className={styles['top-games']}
      closeOnClick
      closeOnEscape
      isCentered
    >
      <TopGamesForm />
    </Modal>
  );
};

export default TopGames;
