import {
  // AC_ENTITY_SHOULD_BE_LOADED,
  AC_ENTITY_LOADING_START,
  AC_ENTITY_LOADING_ERROR,
  AC_ENTITY_LOADING_SUCCESS,
  AC_ENTITY_SAVE_START,
  AC_ENTITY_SAVE_ERROR,
  AC_ENTITY_SAVE_SUCCESS,
} from '../action-types';
import { ENTITY_ID, TOP_GAMES as TOP_GAMES_CONFIG } from '../../../constants';
import {
  // getActions,
  getCellsInRow,
  getGameModel,
  getScore,
} from '../../selectors';
import { getSessionToken } from '../../../core/store/selectors';
import { acAddMessage, acLogout } from '../../../core/store/actions';
import prepareToSave from '../../../utils/store/prepare-to-save';
import { acResetApp } from '../../../core/store/actions/creators/common';
import { acSetGameState } from './field-actions';
import { MESSAGES_TYPE } from '../../../core/constants';
import toErrorMessageObject from '../../../core/utils/to-error-message-object';

const { GAMES, TOP_GAMES } = ENTITY_ID;

/* export const acEntityShouldBeLoaded = entityId => ({
  type: AC_ENTITY_SHOULD_BE_LOADED,
  payload: { entityId },
}); */

export const acEntityLoadingStart = entityId => ({
  type: AC_ENTITY_LOADING_START,
  payload: { entityId },
});

export const acEntityLoadingError = (entityId, error) => ({
  type: AC_ENTITY_LOADING_ERROR,
  payload: { entityId, error },
});

export const acEntityLoadingSuccess = (entityId, data) => ({
  type: AC_ENTITY_LOADING_SUCCESS,
  payload: { entityId, data },
});

export const acEntitySaveStart = entityId => ({
  type: AC_ENTITY_SAVE_START,
  payload: { entityId },
});

export const acEntitySaveError = (entityId, error) => ({
  type: AC_ENTITY_SAVE_ERROR,
  payload: { entityId, error },
});

export const acEntitySaveSuccess = (entityId, data) => ({
  type: AC_ENTITY_SAVE_SUCCESS,
  payload: { entityId, data },
});

const getData = {
  [TOP_GAMES]: (api, state) => {
    const cellsInRow = getCellsInRow(state);
    const sessionToken = getSessionToken(state);
    const { COUNT } = TOP_GAMES_CONFIG;

    return api.game.topGames.getTopGames(cellsInRow, COUNT, sessionToken);
  },
};

const loadEntity = entityId => () => async (dispatch, getState, api) => {
  try {
    dispatch(acEntityLoadingStart(entityId));

    const state = getState();
    const data = await getData[entityId](api, state);

    dispatch(acEntityLoadingSuccess(entityId, data));
  } catch (err) {
    dispatch(acEntityLoadingError(entityId, err));
    if (err?.status === 401) {
      dispatch(acLogout());
      dispatch(acResetApp());
    }
    dispatch(acAddMessage(toErrorMessageObject(err)));
  }
};

export const loadTopGames = loadEntity(TOP_GAMES);

const saveData = {
  [GAMES]: (api, state) => {
    const sessionToken = getSessionToken(state);
    const data = prepareToSave(state);

    return api.game.games.saveGame(data, sessionToken);
  },
};

const saveEntity = entityId => () => async (dispatch, getState, api) => {
  try {
    dispatch(acEntitySaveStart(entityId));

    const result = await saveData[entityId](api, getState());

    dispatch(acEntitySaveSuccess(entityId));

    const msg = {
      status: '',
      date: new Date().toLocaleString(),
    };

    if (result?.isValid === false) {
      msg.message = result.validationMessage;
      msg.type = MESSAGES_TYPE.INFO;
    } else {
      msg.message = 'The game has been saved';
      msg.type = MESSAGES_TYPE.SUCCESS;
    }
    dispatch(acAddMessage(msg));
  } catch (err) {
    dispatch(acEntitySaveError(entityId, err));
    if (err?.status === 401) {
      dispatch(acLogout());
      dispatch(acResetApp());
    }
    dispatch(acAddMessage(toErrorMessageObject(err)));
  }
};

export const saveCurrentGame = saveEntity(GAMES);

export const quickSaveCurrentGame = () => async (dispatch, getState, api) => {
  try {
    dispatch(acEntitySaveStart(GAMES));

    const state = getState();
    const sessionToken = getSessionToken(state);
    const { cellsInRow, actions } = getGameModel(state);
    const actionsLength = actions.length;
    const data = {
      cellsInRow,
      actions:
        actionsLength > 10
          ? actions.slice(actionsLength - 10, actionsLength)
          : actions,
    };

    await api.game.games.quickSave(data, sessionToken);

    dispatch(acEntitySaveSuccess(GAMES));
    dispatch(
      acAddMessage({
        message: 'Saved successfully',
        type: MESSAGES_TYPE.SUCCESS,
        date: new Date().toLocaleString(),
        status: '',
      }),
    );
  } catch (err) {
    dispatch(acEntitySaveError(GAMES, err));
    if (err?.status === 401) {
      dispatch(acLogout());
      dispatch(acResetApp());
    }
    dispatch(acAddMessage(toErrorMessageObject(err)));
  }
};

export const quickLoadCurrentGame = () => async (dispatch, getState, api) => {
  try {
    dispatch(acEntityLoadingStart(GAMES));

    const state = getState();
    const cellsInRow = getCellsInRow(state);
    const sessionToken = getSessionToken(state);
    const game = await api.game.games.quickLoad(cellsInRow, sessionToken);

    dispatch(acEntityLoadingSuccess(GAMES));
    dispatch(acSetGameState(game));
  } catch (err) {
    dispatch(acEntityLoadingError(GAMES, err));
    if (err?.status === 401) {
      dispatch(acLogout());
      dispatch(acResetApp());
    }
    dispatch(acAddMessage(toErrorMessageObject(err)));
  }
};

export const getRank = callback => async (dispatch, getState, api) => {
  try {
    dispatch(acEntityLoadingStart(TOP_GAMES));
    const state = getState();
    const cellsInRow = getCellsInRow(state);
    const score = getScore(state);
    const sessionToken = getSessionToken(state);
    const result = await api.game.topGames.getRank(
      cellsInRow,
      score,
      sessionToken,
    );

    callback(result);
    dispatch(acEntityLoadingSuccess(TOP_GAMES));
  } catch (err) {
    dispatch(acEntityLoadingError(TOP_GAMES, err));
    if (err?.status === 401) {
      dispatch(acLogout());
      dispatch(acResetApp());
    }
    dispatch(acAddMessage(toErrorMessageObject(err)));
  }
};
