import COLORS from './colors';

const COLORS_LIST = [
  COLORS.YELLOW, // 2
  COLORS.YELLOW_GREEN, // 4
  COLORS.BLUE, // 8
  COLORS.DARK_ORANGE, // 16
  COLORS.LIGHT_RED, // 32
  COLORS.DARK_NAVY, // 64
  COLORS.LIGHT_VIOLET, // 128
  COLORS.BROWN, // 256
  COLORS.DARK_RED, // 512
  COLORS.DARK_GREEN, // 1024
  COLORS.ORANGE, // 2048
  COLORS.VIOLET, // 4096
  COLORS.ROUGE, // 8128
  COLORS.LIGHT_NAVY, // 16356
  COLORS.LIGHT_YELLOW,
  COLORS.RED,
  COLORS.GREEN,
  COLORS.NAVY,
  COLORS.DARK_VIOLET,
  COLORS.DARK_YELLOW,
  COLORS.LIGHT_ORANGE,
  COLORS.LIGHT_BLUE,
  COLORS.LIGHT_GREEN,
  COLORS.DARK_BLUE,
];

export default COLORS_LIST;
