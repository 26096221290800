import { get, parseResponse } from '../core/utils/network-requests';

import { URLS } from '../constants';

const getTopGames = async (cellsInRow, count, sessionToken) => {
  const url = URLS.GET_TOP_GAMES.replace('{cellsInRow}', cellsInRow).replace(
    '{count}',
    count,
  );

  const response = await get({
    url,
    headers: {
      'session-token': sessionToken,
    },
  });

  const parsedResponse = await parseResponse(response);

  if (!response.ok) {
    throw parsedResponse;
  }

  return parsedResponse;
};

const getRank = async (cellsInRow, score, sessionToken) => {
  const url = `${URLS.GAME_RANK}?cir=${cellsInRow}&s=${score}`;

  const response = await get({
    url,
    headers: {
      'session-token': sessionToken,
    },
  });

  const parsedResponse = await parseResponse(response);

  if (!response.ok) {
    throw parsedResponse;
  }

  return parsedResponse;
};

const topGamesApi = {
  getTopGames,
  getRank,
};

export default topGamesApi;
