import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Form from '../../../../../core/components/Form';
import FormFieldset from '../../../../../core/components/Form/shared/FormFieldset';
import Legend from '../../../../../core/components/Form/shared/Legend';
import List from '../../../../../core/components/List';
import ListItem from '../../../../../core/components/List/shared/ListItem';
import getColorByValue from '../../../../../utils/store/get-color-by-value';
import eventEmitter from '../../../../../core/components/App/shared/event-emitter';
import { EVENTS, COMMON } from '../../../../../core/constants';

import styles from './GameIsOverForm.module.scss';

const GameIsOverForm = ({
  tileStats,
  score,
  isLogged,
  closeModal,
  getRank,
}) => {
  const [rank, setRank] = useState(0);
  const formSection = useRef(null);
  const list = useRef(null);

  const handleResize = () => {
    const screenHeight = document.body.offsetHeight;
    if (formSection.current && list.current) {
      const formSectionTop = formSection.current.getBoundingClientRect().top;
      let formSectionHeight = screenHeight - formSectionTop - 7 * COMMON.REM;
      const formSectionBottom = formSectionTop + formSectionHeight;
      const {
        top: listTop,
        height: listHeight,
      } = list.current.getBoundingClientRect();
      const listBottom = listTop + listHeight;
      if (listBottom < formSectionBottom) {
        formSectionHeight = listBottom - formSectionTop;
      }

      formSection.current.style.height = `${formSectionHeight}px`;
    }
  };

  useEffect(() => {
    eventEmitter.on(EVENTS.RESIZE, handleResize);
    handleResize();
  }, []);

  useEffect(() => {
    if (score && isLogged) {
      getRank(result => setRank(result.rank));
    }
  }, [getRank, setRank, score, isLogged]);

  const stats = useMemo(() => {
    return tileStats.map(({ value, count }) => {
      return (
        <ListItem key={value} className={styles['stats-list__item']}>
          <span
            className={styles['tile-value']}
            style={{ backgroundColor: getColorByValue(value) }}
          >
            {value}
          </span>
          <span>x {count}</span>
        </ListItem>
      );
    });
  }, [tileStats]);

  return (
    <Form
      name="gameIsOver"
      className={styles['game-is-over-form']}
      withCross
      onCrossClick={closeModal}
    >
      <FormFieldset className={styles['form-content']}>
        <Legend>Game Is Over!</Legend>
        <section className={styles['form-content__section']} ref={formSection}>
          <p
            className={classNames(styles['record-line'], styles['score-info'])}
          >
            Score: <span className={styles['big-text']}>{score}</span>
          </p>
          {isLogged && (
            <p
              className={classNames(
                styles['record-line'],
                styles['score-info'],
              )}
            >
              Your rank: <span className={styles['big-text']}>{rank}</span>
            </p>
          )}
          <p className={styles['record-line']}>Tiles:</p>
          <List className={styles['stats-list']} childRef={list}>
            {stats}
          </List>
        </section>
      </FormFieldset>
    </Form>
  );
};

GameIsOverForm.propTypes = {
  isLogged: PropTypes.bool,
  tileStats: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.number, count: PropTypes.number }),
  ),
  score: PropTypes.number,
  closeModal: PropTypes.func.isRequired,
  getRank: PropTypes.func.isRequired,
};

GameIsOverForm.defaultProps = {
  isLogged: false,
  tileStats: [],
  score: 0,
};

export default GameIsOverForm;
