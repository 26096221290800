const COLORS = {
  RED: 'rgba(238, 44, 44, 1)',
  ORANGE: 'rgba(238, 154, 0, 1)',
  YELLOW: 'rgba(238, 180, 34, 1)', // 'rgba(255, 204, 51, 1)'
  GREEN: 'rgba(0, 238, 0, 1)',
  BLUE: 'rgba(92, 172, 238, 1)',
  NAVY: 'rgba(28, 134, 238, 1)',
  VIOLET: 'rgba(122, 103, 238, 1)',
  DARK_RED: 'rgba(205, 38, 38, 1)',
  DARK_ORANGE: 'rgb(255,72,0)', // 'rgba(205, 133, 0, 1)',
  DARK_YELLOW: 'rgba(205, 155, 29, 1)',
  DARK_GREEN: 'rgb(20,164,79)', // 'rgba(102, 153, 51, 1)',
  DARK_BLUE: 'rgba(79, 148, 205, 1)',
  DARK_NAVY: 'rgba(24, 116, 205, 1)',
  DARK_VIOLET: 'rgba(105, 89, 205, 1)',
  LIGHT_RED: 'rgb(252,122,122)', // 'rgba(255, 48, 48, 1)',
  LIGHT_ORANGE: 'rgb(255,140,0)',
  LIGHT_YELLOW: 'rgba(255, 193, 37, 1)', // 'rgba(204, 204, 51, 1)',
  LIGHT_GREEN: 'rgba(0, 255, 0, 1)',
  LIGHT_BLUE: 'rgba(99, 184, 255, 1)',
  LIGHT_NAVY: 'rgba(30, 144, 255, 1)',
  LIGHT_VIOLET: 'rgba(153, 51, 204, 1)', // 'rgba(131, 111, 255, 1)',
  ROUGE: 'rgba(255, 0, 153, 1)',
  BROWN: 'rgba(173, 74, 8, 1)',
  YELLOW_GREEN: 'rgba(154, 205, 50, 1)',
};

export default COLORS;
