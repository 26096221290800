import { getCellsInRow, getMatrix, getScore } from '../../store/selectors';

const prepareToSave = state => {
  const cellsInRow = getCellsInRow(state);
  const score = getScore(state);
  const matrix = getMatrix(state);

  return {
    cellsInRow,
    score,
    matrix,
  };
};

export default prepareToSave;
