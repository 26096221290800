import { getLast } from '../../core/utils/common-utils';
import Tile from '../../components/domain/Tile/Tile';
import getTileSize from './get-tile-size';

const setGameState = (state, payload) => {
  const { actions, cellsInRow } = payload;

  const { score, tiles: tilesData } = getLast(actions);
  const size = getTileSize(cellsInRow);

  const tiles = tilesData.map(({ value, x, y }) => {
    return new Tile({
      value,
      x,
      y,
      size,
    });
  });

  return {
    ...state,
    cellsInRow,
    score,
    actions,
    canceledActions: [],
    tiles,
  };
};

export default setGameState;
