import { FIELD } from '../../constants';
import roundedRect from '../../core/utils/canvas/rounded-rect';
import getTileSize from '../store/get-tile-size';

const { CANVAS_SIZE } = FIELD;

const drawEmptyField = (context, cellsInRow) => {
  context.clearRect(0, 0, CANVAS_SIZE, CANVAS_SIZE);
  const tileSize = getTileSize(cellsInRow);
  const halfSize = tileSize / 2;
  for (let y = 0; y < cellsInRow; y++) {
    for (let x = 0; x < cellsInRow; x++) {
      const cx = tileSize * x + halfSize;
      const cy = tileSize * y + halfSize;
      context.strokeStyle = 'rgba(80, 80, 80, 1)';
      context.fillStyle = 'rgba(80, 80, 80, 1)';
      roundedRect(context, cx, cy, tileSize - 4, tileSize - 4, 8, true);
    }
  }
};

export default drawEmptyField;
