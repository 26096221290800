import React from 'react';
import PropTypes from 'prop-types';

import styles from './TopGamesItem.module.scss';
import getColorByValue from '../../../../../../../utils/store/get-color-by-value';

const TopGamesItem = ({ rank, login, matrix, score, onClick }) => {
  const max = matrix.reduce((acc, curr) => Math.max(acc, curr), 0);
  const backgroundColor = getColorByValue(max);

  return (
    <li className={styles['top-games-item']} onClick={() => onClick(matrix)}>
      <span className={styles['top-games-item__rank']}>{rank}</span>
      <span className={styles['top-games-item__record']}>
        <span>{login}</span>
        <span>{score}</span>
        <span style={{ backgroundColor }} className={styles['record-max']}>
          {max}
        </span>
      </span>
    </li>
  );
};

TopGamesItem.propTypes = {
  rank: PropTypes.number.isRequired,
  login: PropTypes.string,
  matrix: PropTypes.arrayOf(PropTypes.number),
  score: PropTypes.number,
  onClick: PropTypes.func,
};

TopGamesItem.defaultProps = {
  login: '',
  matrix: [],
  score: 0,
  onClick: () => () => {},
};

export default TopGamesItem;
