import {
  AC_CHANGE_CELLS_IN_ROW,
  AC_CREATE_TILE,
  AC_REFRESH_TILES,
  AC_TILES_TO_LEFT,
  AC_TILES_TO_RIGHT,
  AC_TILES_TO_UP,
  AC_TILES_TO_DOWN,
  AC_UNDO_ACTION,
  AC_REDO_ACTION,
  AC_SET_GAME_STATE,
  AC_RESTART_GAME,
} from '../action-types';

export const acChangeCellsInRow = cellsInRow => ({
  type: AC_CHANGE_CELLS_IN_ROW,
  payload: { cellsInRow },
});

export const acRestartGame = () => ({
  type: AC_RESTART_GAME,
});

export const acCreateTile = () => ({
  type: AC_CREATE_TILE,
});

export const acRefreshTiles = () => ({
  type: AC_REFRESH_TILES,
});

export const acTilesToLeft = () => ({
  type: AC_TILES_TO_LEFT,
});

export const acTilesToRight = () => ({
  type: AC_TILES_TO_RIGHT,
});

export const acTilesToUp = () => ({
  type: AC_TILES_TO_UP,
});

export const acTilesToDown = () => ({
  type: AC_TILES_TO_DOWN,
});

export const acUndoAction = () => ({
  type: AC_UNDO_ACTION,
});

export const acRedoAction = () => ({
  type: AC_REDO_ACTION,
});

export const acSetGameState = payload => ({
  type: AC_SET_GAME_STATE,
  payload,
});

/* export const acSetLayout = layout => ({
  type: AC_SET_LAYOUT,
  payload: { layout },
});

export const acSetSquares = squares => ({
  type: AC_SET_SQUARES,
  payload: { squares },
}); */
