import { get } from 'lodash';

import { ENTITY_ID, MODELS } from '../../constants';
import { getSaveStatusKey } from '../../utils/store/get-status-key';
import getEntityPrefix from '../../utils/store/get-entity-prefix';

const { TOP_GAMES } = ENTITY_ID;

const getEntitySaveStatus = entityId => state =>
  get(state, `${MODELS.NETWORKING}.${getSaveStatusKey(entityId)}`);

const getEntityLoadStatus = entityId => state =>
  get(state, `${MODELS.NETWORKING}.${getEntityPrefix(entityId)}LoadStatus`, 0);

const getEntityData = entityId => state =>
  get(state, `${MODELS.NETWORKING}.${getEntityPrefix(entityId)}Data`, null);

export const loadStatusSelectorsList = Object.values(ENTITY_ID).map(entityId =>
  getEntityLoadStatus(entityId),
);

export const saveStatusSelectorsList = Object.values(ENTITY_ID).map(entityId =>
  getEntitySaveStatus(entityId),
);

export const getTopGamesData = getEntityData(TOP_GAMES);
