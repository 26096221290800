import { connect } from 'react-redux';

import TopGamesForm from './TopGamesForm';
import { getTopGamesData } from '../../../../../store/selectors/networking-selectors';
import { loadTopGames } from '../../../../../store/actions';
import {
  acAddModal,
  acRemoveLastModal,
  acSetModalState,
} from '../../../../../core/store/actions';
import { MODALS_ID } from '../../../../../constants';
import { getTopGamesModalShouldBeLoaded } from '../../../../../store/selectors';

const mapStateToProps = state => ({
  shouldBeLoaded: getTopGamesModalShouldBeLoaded(state),
  topGamesData: getTopGamesData(state),
});

const mapDispatchToProps = {
  closeModal: acRemoveLastModal,
  loadTopGames,
  openGameLayout: matrix => acAddModal(MODALS_ID.GAME_LAYOUT, { matrix }),
  setIsLoaded: () => acSetModalState(MODALS_ID.TOP_GAMES, { isLoaded: true }),
};

export default connect(mapStateToProps, mapDispatchToProps)(TopGamesForm);
