import { connect } from 'react-redux';

import GameLayoutForm from './GameLayoutForm';
import { acRemoveLastModal } from '../../../../../core/store/actions';
import { getGameLayoutMatrix } from '../../../../../store/selectors';

const mapStateToProps = state => ({
  matrix: getGameLayoutMatrix(state),
});

const mapDispatchToProps = {
  closeModal: acRemoveLastModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(GameLayoutForm);
