import { MODALS_ID } from '../../../constants';

const getMainMenuOptions = ({
  isLogged,
  parentId,
  closeAllModals,
  goTo,
  openModal,
  ...rest
}) => {
  const {
    isCurrentGameCompleted,
    setCellsInRow,
    saveCurrentGame,
    quickSaveCurrentGame,
    quickLoadCurrentGame,
  } = rest;

  const changeFieldSize = size => () => {
    setCellsInRow(size);
    closeAllModals();
  };

  return [
    {
      id: 'newGame',
      label: 'New game',
      onClick: goTo('newGame'),
      children: [
        {
          id: 'back',
          label: '..',
          onClick: goTo(),
        },
        {
          id: `size3`,
          label: `3 x 3 (9 cells)`,
          onClick: changeFieldSize(3),
        },
        {
          id: `size4`,
          label: `4 x 4 (16 cells)`,
          onClick: changeFieldSize(4),
        },
        {
          id: `size5`,
          label: `5 x 5 (25 cells)`,
          onClick: changeFieldSize(5),
        },
        {
          id: `size6`,
          label: `6 x 6 (36 cells)`,
          onClick: changeFieldSize(6),
        },
        {
          id: `size7`,
          label: `7 x 7 (49 cells)`,
          onClick: changeFieldSize(7),
        },
        {
          id: `size8`,
          label: `8 x 8 (64 cells)`,
          onClick: changeFieldSize(8),
        },
      ],
    },
    {
      id: 'quickSave',
      label: 'Quick save',
      isVisible: isLogged && !isCurrentGameCompleted,
      onClick: () => {
        quickSaveCurrentGame();
        closeAllModals();
      },
    },
    {
      id: 'quickLoad',
      label: 'Quick load',
      isVisible: isLogged,
      onClick: () => {
        quickLoadCurrentGame();
        closeAllModals();
      },
    },
    {
      id: 'saveCurrentGame',
      label: 'Save current game',
      isVisible: isLogged && isCurrentGameCompleted,
      onClick: async () => {
        await saveCurrentGame();
        closeAllModals();
      },
    },
    {
      id: 'topGameTable',
      label: 'Top Games Table',
      isVisible: isLogged,
      onClick: () => {
        openModal(MODALS_ID.TOP_GAMES, { isLoaded: false });
      },
    },
  ];
};

export default getMainMenuOptions;
