import { connect } from 'react-redux';

import Controller from './Controller';
import { getIsModalMode } from '../../core/store/selectors';
import {
  acTilesToDown,
  acTilesToLeft,
  acTilesToRight,
  acTilesToUp,
  acUndoAction,
  acRedoAction,
} from '../../store/actions';

const mapStateToProps = state => ({
  isModalMode: getIsModalMode(state),
});

const mapDispatchToProps = {
  tilesToLeft: acTilesToLeft,
  tilesToRight: acTilesToRight,
  tilesToUp: acTilesToUp,
  tilesToDown: acTilesToDown,
  acUndoAction,
  acRedoAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Controller);
