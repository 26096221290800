import { get } from 'lodash';
import { createSelector } from 'reselect';
import { MODELS } from '../../constants';

export const getGameModel = state => get(state, MODELS.MODEL);

export const getCellsInRow = state => get(state, MODELS.MODEL_CELLS_IN_ROW);

export const getActions = state => get(state, MODELS.MODEL_ACTIONS, []);

export const getTiles = state => get(state, MODELS.MODEL_TILES, []);

export const getMatrix = createSelector(
  [getTiles, getCellsInRow],
  (tiles, cellsInRow) => {
    const matrix = [];
    for (let y = 0; y < cellsInRow; y++) {
      for (let x = 0; x < cellsInRow; x++) {
        const tile = tiles.find(
          ({ props: { x: tileX, y: tileY } }) => tileX === x && tileY === y,
        );
        const value = tile?.props.value || 0;
        matrix.push(value);
      }
    }

    return matrix;
  },
);

export const getIsMoving = state =>
  getTiles(state).some(tile => tile.getIsMoving() === true);

export const getShouldAddTile = state =>
  get(state, MODELS.MODEL_SHOULD_ADD_TILE, false);

export const getScore = state => get(state, MODELS.MODEL_SCORE);

export const getIsCurrentGameCompleted = createSelector(
  [getTiles, getCellsInRow],
  (tiles, cellsInRow) => {
    let isCompleted = true;

    const check = lineKey => {
      const colKey = lineKey === 'y' ? 'x' : 'y';
      for (let lineIndex = 0; lineIndex < cellsInRow; lineIndex++) {
        const line = tiles
          .filter(
            ({ props: { [lineKey]: lineCoordinate } }) =>
              lineCoordinate === lineIndex,
          )
          .sort((tile1, tile2) => tile2.props[colKey] - tile1.props[colKey])
          .map(({ props: { value } }) => value);
        if (line.length < cellsInRow) {
          isCompleted = false;
          break;
        }
        for (let i = 0; i < line.length - 1; i++) {
          if (line[i] === line[i + 1]) {
            isCompleted = false;
            break;
          }
        }
      }
    };

    check('x');
    if (isCompleted) {
      check('y');
    }

    return isCompleted;
  },
);

export const getTilesStats = createSelector([getTiles], tiles => {
  return tiles
    .map(({ props: { value } }) => value)
    .reduce((acc, curr, _, arr) => {
      return !acc.some(item => item.value === curr)
        ? [...acc, { value: curr, count: arr.filter(v => v === curr).length }]
        : acc;
    }, [])
    .sort((s1, s2) => s2.value - s1.value);
});
