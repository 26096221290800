import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../../../../core/components/Form/shared/FormButton';

import styles from './TouchScreenToolbar.module.scss';

const TouchScreenToolbar = ({ childRef, isGameCompleted, redo, undo }) => {
  return (
    !isGameCompleted && (
      <div className={styles['touch-screen-toolbar']} ref={childRef}>
        <Button label="Undo" onClick={undo} />
        <Button label="Redo" onClick={redo} />
      </div>
    )
  );
};

TouchScreenToolbar.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  childRef: PropTypes.object.isRequired,
  isGameCompleted: PropTypes.bool,
  redo: PropTypes.func.isRequired,
  undo: PropTypes.func.isRequired,
};

TouchScreenToolbar.defaultProps = {
  isGameCompleted: false,
};

export default TouchScreenToolbar;
