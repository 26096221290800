import { connect } from 'react-redux';

import FieldService from './FieldService';
import {
  getCellsInRow,
  getIsMoving,
  getShouldAddTile,
  getTiles,
} from '../../../store/selectors';
import { acCreateTile } from '../../../store/actions';

const mapStateToProps = state => ({
  cellsInRow: getCellsInRow(state),
  tiles: getTiles(state),
  shouldAddTile: getShouldAddTile(state),
  isMoving: getIsMoving(state),
});

const mapDispatchToProps = {
  createTile: acCreateTile,
};

export default connect(mapStateToProps, mapDispatchToProps)(FieldService);
