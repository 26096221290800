import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactDom from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import eventEmitter from '../App/shared/event-emitter';
import delay from '../../utils/delay';
import { EVENTS } from '../../constants';

import styles from './Modal.module.scss';

const Modal = ({
  children,
  className,
  closeOnClick,
  closeOnEscape,
  id,
  isCentered,
  isOpen,
  acRemoveModal,
  onClose,
}) => {
  const [isVisible, setIsVisible] = useState(isOpen);

  const modal = useRef(null);
  const root = useRef(document.getElementById('modal-root'));
  const isOpenedAndVisible = useRef(isOpen && isVisible);
  isOpenedAndVisible.current = isOpen && isVisible;

  const close = useCallback(() => {
    if (isOpenedAndVisible.current) {
      acRemoveModal(id);
      onClose(id);
    }
  }, [onClose, id, acRemoveModal]);

  const handleClick = useCallback(() => {
    if (closeOnClick) {
      close();
    }
  }, [closeOnClick, close]);

  const handleEscape = useCallback(() => {
    if (closeOnEscape) {
      close();
    }
  }, [closeOnEscape, close]);

  useEffect(() => {
    if (isOpen && !isVisible) {
      setIsVisible(true);
    }
    if (isVisible && !isOpen) {
      modal.current.classList.add(styles['modal--hide-start']);
      delay(300).then(() => {
        if (modal.current) {
          modal.current.classList.add(styles['modal--hide-end']);
          modal.current.classList.remove(styles['modal--hide-start']);
        }
        setIsVisible(false);
      });
    }
  }, [isOpen, isVisible]);

  useEffect(() => {
    eventEmitter.on(EVENTS.ESCAPE, handleEscape);
  }, [handleEscape]);

  return (
    isVisible &&
    ReactDom.createPortal(
      <div
        className={classNames(
          styles['modal'],
          { [styles['modal--centered']]: isCentered },
          className && className,
        )}
        id={id}
        ref={modal}
        role="dialog"
        onClick={handleClick}
        onKeyPress={null}
      >
        {children}
      </div>,
      root.current,
    )
  );
};

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  className: PropTypes.string,
  closeOnClick: PropTypes.bool,
  closeOnEscape: PropTypes.bool,
  id: PropTypes.string.isRequired,
  isCentered: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  acRemoveModal: PropTypes.func,
  onClose: PropTypes.func,
};

Modal.defaultProps = {
  className: '',
  closeOnClick: false,
  closeOnEscape: false,
  isCentered: false,
  acRemoveModal: () => {},
  onClose: () => {},
};

export default Modal;
