import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import FieldToolbar from './shared/Toolbar';
import TouchScreenToolbar from './shared/TouchScreenToolbar';
import eventEmitter from '../../../../core/components/App/shared/event-emitter';
import delay from '../../../../core/utils/delay';
import { COMMON, EVENTS } from '../../../../core/constants';
import drawEmptyField from '../../../../utils/canvas/draw-empty-field';

import styles from './Field.module.scss';

const { REM } = COMMON;
const { KEY_DOWN, KEY_LEFT, KEY_RIGHT, KEY_UP, RESIZE } = EVENTS;

const Field = ({
  isTilesMoving,
  tiles,
  cellsInRow,
  touchScreenDetected,
  refreshTiles,
}) => {
  const initialRef = {
    clientHeight: 0,
    offsetHeight: 0,
    offsetWidth: 0,
  };
  const field = useRef(initialRef);
  const toolbar = useRef(initialRef);
  const touchScreenToolbar = useRef(null);
  const content = useRef(initialRef);
  const canvas = useRef(null);
  const [fieldSize, setFieldSize] = useState(0);
  const touchStart = useRef({ x: 0, y: 0 });

  const handleResize = () => {
    const screenHeight = document.body.offsetHeight;
    const contentTop = content.current.getBoundingClientRect().top;
    const contentHeight = screenHeight - contentTop - REM;
    if (field.current && toolbar.current && content.current) {
      const toolbarWidth = toolbar.current.offsetWidth;
      const size = Math.min(toolbarWidth, contentHeight);
      setFieldSize(size);
    }
  };

  const renderSquares = useCallback(() => {
    const context = canvas.current.getContext('2d');
    let isMoving = false;

    drawEmptyField(context, cellsInRow);
    tiles.forEach(tile => {
      tile.update(context, tiles);
      isMoving = tile.getIsMoving() || isMoving;
    });
    if (isMoving) {
      requestAnimationFrame(renderSquares);
    } else {
      delay(10).then(() => {
        refreshTiles();
      });
    }
  }, [cellsInRow, tiles, refreshTiles]);

  const handleTouchStart = useCallback(e => {
    e.preventDefault();
    // e.stopPropagation();
    const { clientX, clientY } = e.changedTouches[0];
    touchStart.current = { x: clientX, y: clientY };
  }, [touchStart]);

  const handleTouchEnd = useCallback(e => {
    e.preventDefault();
    // e.stopPropagation();
    const { clientX, clientY } = e.changedTouches[0];
    const deltaX = clientX - touchStart.current.x;
    const deltaY = clientY - touchStart.current.y;

    const isHorizontal = Math.abs(deltaX) > Math.abs(deltaY);

    let event = '';

    switch (true) {
      case isHorizontal && deltaX > 0:
        event = KEY_RIGHT;
        break;

      case isHorizontal && deltaX < 0:
        event = KEY_LEFT;
        break;

      case deltaY > 0:
        event = KEY_DOWN;
        break;

      case deltaY < 0:
        event = KEY_UP;
        break;

      default:
    }

    if (event) {
      eventEmitter.emit(event, { ctrlKey: false });
    }
  }, [touchStart]);

  useEffect(() => {
    eventEmitter.on(RESIZE, handleResize);
  }, []);

  useEffect(() => {
    if (touchScreenDetected) {
      const canvasElm = document.getElementById('canvas');
      canvasElm.addEventListener('touchstart', handleTouchStart);
      canvasElm.addEventListener('touchend', handleTouchEnd);
    }
  }, [touchScreenDetected, handleTouchStart, handleTouchEnd]);

  useEffect(() => {
    handleResize();
  }, [touchScreenDetected]);

  useEffect(() => {
    if (isTilesMoving) {
      requestAnimationFrame(renderSquares);
    }
  }, [tiles, isTilesMoving, renderSquares]);

  return (
    <div className={styles['field']} ref={field}>
      <FieldToolbar childRef={toolbar} />
      <TouchScreenToolbar childRef={touchScreenToolbar} />
      <div id="fieldContent" className={styles['field-content']} ref={content}>
        <div
          className={styles['canvas-wrapper']}
          style={{ width: fieldSize, height: fieldSize }}
        >
          <canvas
            id="canvas"
            className={styles['canvas']}
            height={800}
            width={800}
            ref={canvas}
          >
            Your browser does not support canvas graphic
          </canvas>
        </div>
      </div>
    </div>
  );
};

Field.propTypes = {
  cellsInRow: PropTypes.number.isRequired,
  isTilesMoving: PropTypes.bool,
  tiles: PropTypes.arrayOf(PropTypes.shape({})),
  touchScreenDetected: PropTypes.bool,
  refreshTiles: PropTypes.func,
};

Field.defaultProps = {
  tiles: [],
  isTilesMoving: false,
  touchScreenDetected: false,
  refreshTiles: () => {},
};

export default Field;
