import {
  AC_CHANGE_CELLS_IN_ROW,
  AC_RESTART_GAME,
  AC_CREATE_TILE,
  AC_REFRESH_TILES,
  AC_TILES_TO_LEFT,
  AC_TILES_TO_RIGHT,
  AC_TILES_TO_UP,
  AC_TILES_TO_DOWN,
  AC_UNDO_ACTION,
  AC_REDO_ACTION,
  AC_SET_GAME_STATE,
} from '../actions/action-types';
import { FIELD } from '../../constants';
import createTile from '../../utils/store/create-tile';
import moveTilesValue from '../../utils/store/move-tiles-value';
import undoAction from '../../utils/store/undo-action';
import redoAction from '../../utils/store/redo-action';
import setGameState from '../../utils/store/set-game-state';

/* import Tile from '../../components/domain/Tile/Tile';

const mockTiles = [2, 4, 8, 16, 32, 64, 128, 256, 512, 1024, 2048, 4096, 8192, 16384].map((value, i) => {
  const size = FIELD.CANVAS_SIZE / 4;
  const y = Math.floor(i / 4);
  const x = i % 4;
  return new Tile({ value, x, y, size });
}); */

const initialState = {
  actions: [],
  canceledActions: [],
  cellsInRow: FIELD.DEFAULT_CELLS_IN_ROW,
  score: 0,
  tiles: [],
  // tiles: mockTiles,
  shouldAddTile: true,
};

const modelReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case AC_CHANGE_CELLS_IN_ROW:
      return {
        ...initialState,
        cellsInRow: payload.cellsInRow,
        // ...state,
        // actions: [],
        // canceledActions: [],
        // cellsInRow: payload.cellsInRow,
        // score: 0,
        // shouldAddTile: true,
        // tiles: [],
      };

    case AC_RESTART_GAME:
      return {
        ...initialState,
        cellsInRow: state.cellsInRow,
      };

    case AC_CREATE_TILE:
      return createTile(state);

    case AC_REFRESH_TILES:
      return {
        ...state,
        tiles: [...state.tiles],
      };

    case AC_TILES_TO_LEFT:
      return moveTilesValue(state, -1, 0);

    case AC_TILES_TO_RIGHT:
      return moveTilesValue(state, 1, 0);

    case AC_TILES_TO_UP:
      return moveTilesValue(state, 0, -1);

    case AC_TILES_TO_DOWN:
      return moveTilesValue(state, 0, 1);

    case AC_UNDO_ACTION:
      return undoAction(state);

    case AC_REDO_ACTION:
      return redoAction(state);

    case AC_SET_GAME_STATE:
      return setGameState(state, payload);

    default:
      return state;
  }
};

export default modelReducer;
