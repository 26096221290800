import { connect } from 'react-redux';

import Toolbar from './Toolbar';
import { getIsLogged } from '../../../../../../core/store/selectors';
import { getScore } from '../../../../../../store/selectors';
// import {
//   getBestCount,
//   getIsCurrentGameCompleted,
// } from '../../../../../../store/selectors';

const mapStateToProps = state => ({
  // bestResult: getBestCount(state),
  score: getScore(state),
  // isCompleted: getIsCurrentGameCompleted(state),
  isLogged: getIsLogged(state),
});

export default connect(mapStateToProps)(Toolbar);
