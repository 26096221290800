import ENTITY_ID from './entity-id';

const { GAMES, TOP_GAMES } = ENTITY_ID;

const ENTITIES = {
  [GAMES]: {
    prefix: 'games',
  },
  [TOP_GAMES]: {
    prefix: 'topGames',
  },
};

export default ENTITIES;
