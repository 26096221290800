import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import eventEmitter from '../../core/components/App/shared/event-emitter';
import { EVENTS } from '../../core/constants';

const { KEY_DOWN, KEY_LEFT, KEY_RIGHT, KEY_UP, REDO, UNDO } = EVENTS;

class Controller extends PureComponent {
  componentDidMount() {
    eventEmitter.on(KEY_LEFT, this.handleLeft);
    eventEmitter.on(KEY_RIGHT, this.handleRight);
    eventEmitter.on(KEY_UP, this.handleUp);
    eventEmitter.on(KEY_DOWN, this.handleDown);
    eventEmitter.on(REDO, this.handleRedo);
    eventEmitter.on(UNDO, this.handleUndo);
  }

  componentWillUnmount() {
    eventEmitter.off(KEY_LEFT, this.handleLeft);
    eventEmitter.off(KEY_RIGHT, this.handleRight);
    eventEmitter.off(KEY_UP, this.handleUp);
    eventEmitter.off(KEY_DOWN, this.handleDown);
    eventEmitter.off(REDO, this.handleRedo);
    eventEmitter.off(UNDO, this.handleUndo);
  }

  gameIsActive = () => {
    const { isModalMode } = this.props;

    return !isModalMode;
  };

  handleLeft = () => {
    // console.log('LEFT');
    const { tilesToLeft } = this.props;

    if (this.gameIsActive()) {
      tilesToLeft();
    }
  };

  handleRight = () => {
    // console.log('RIGHT');
    const { tilesToRight } = this.props;

    if (this.gameIsActive()) {
      tilesToRight();
    }
  };

  handleUp = () => {
    // console.log('UP');
    const { tilesToUp } = this.props;

    if (this.gameIsActive()) {
      tilesToUp();
    }
  };

  handleDown = () => {
    // console.log('DOWN');
    const { tilesToDown } = this.props;

    if (this.gameIsActive()) {
      tilesToDown();
    }
  };

  handleRedo = () => {
    // console.log('REDO');
    const { acRedoAction } = this.props;

    if (this.gameIsActive()) {
      acRedoAction();
    }
  };

  handleUndo = () => {
    // console.log('UNDO');
    const { acUndoAction } = this.props;

    if (this.gameIsActive()) {
      acUndoAction();
    }
  };

  render() {
    return null;
  }
}

Controller.propTypes = {
  isModalMode: PropTypes.bool,
  tilesToLeft: PropTypes.func,
  tilesToRight: PropTypes.func,
  tilesToUp: PropTypes.func,
  tilesToDown: PropTypes.func,
  acRedoAction: PropTypes.func,
  acUndoAction: PropTypes.func,
};

Controller.defaultProps = {
  isModalMode: false,
  tilesToLeft: () => {},
  tilesToRight: () => {},
  tilesToUp: () => {},
  tilesToDown: () => {},
  acRedoAction: () => {},
  acUndoAction: () => {},
};

export default Controller;
