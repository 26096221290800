const ROUTES = [
  {
    id: 'GAME_2048',
    url: '/',
    menuLabel: 'Game "2048"',
    title: 'Game "2048"',
    pageTitle: 'Feature - Game "2048"',
  },
];

export default ROUTES;
