import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Form from '../../../../../core/components/Form';
import FormFieldset from '../../../../../core/components/Form/shared/FormFieldset';
import Legend from '../../../../../core/components/Form/shared/Legend';
import List from '../../../../../core/components/List';
import TopGamesItem from './shared/TopGamesItem';

import styles from './TopGamesForm.module.scss';
import { isFilledArray } from '../../../../../core/utils/common-utils';

const TopGamesForm = ({
  shouldBeLoaded,
  topGamesData,
  loadTopGames,
  closeModal,
  openGameLayout,
  setIsLoaded,
}) => {
  // console.log('TopGamesForm: data', topGamesData);

  useEffect(() => {
    if (shouldBeLoaded) {
      loadTopGames().then(setIsLoaded);
    }
  }, [loadTopGames, setIsLoaded, shouldBeLoaded]);

  const items = isFilledArray(topGamesData) ? (
    topGamesData.map(({ id, login, score, matrix }, i) => {
      const rank = i + 1;

      return (
        <TopGamesItem
          key={id}
          rank={rank}
          login={login}
          score={score}
          matrix={matrix}
          onClick={openGameLayout}
        />
      );
    })
  ) : (
    <li>No data to display</li>
  );

  return (
    <Form
      className={styles['top-games-form']}
      name="topGames"
      withCross
      onCrossClick={closeModal}
    >
      <FormFieldset className={styles['top-games-form-fieldset']}>
        <Legend className={styles['top-games-form-fieldset__title']}>
          Top Results
        </Legend>
        <section className={styles['top-games-form-fieldset__content']}>
          <List className={styles['top-list']}>{items}</List>
        </section>
      </FormFieldset>
    </Form>
  );
};

TopGamesForm.propTypes = {
  shouldBeLoaded: PropTypes.bool,
  topGamesData: PropTypes.arrayOf(PropTypes.shape({})),
  closeModal: PropTypes.func,
  loadTopGames: PropTypes.func,
  openGameLayout: PropTypes.func,
  setIsLoaded: PropTypes.func,
};

TopGamesForm.defaultProps = {
  shouldBeLoaded: false,
  topGamesData: null,
  closeModal: () => {},
  loadTopGames: () => {},
  openGameLayout: () => {},
  setIsLoaded: () => {},
};

export default TopGamesForm;
