import { connect } from 'react-redux';

import Field from './Field';
import { getTouchScreenDetected } from '../../../../core/store/selectors';
import {
  getCellsInRow,
  getIsMoving,
  getTiles,
} from '../../../../store/selectors';
import { acRefreshTiles } from '../../../../store/actions';

const mapStateToProps = state => ({
  cellsInRow: getCellsInRow(state),
  touchScreenDetected: getTouchScreenDetected(state),
  tiles: getTiles(state),
  isTilesMoving: getIsMoving(state),
});

const mapDispatchToProps = {
  refreshTiles: acRefreshTiles,
};

export default connect(mapStateToProps, mapDispatchToProps)(Field);
