const MODELS = {
  NETWORKING: 'game.networking',
  MODEL: 'game.model',
  MODEL_ACTIONS: 'game.model.actions',
  MODEL_CELLS_IN_ROW: 'game.model.cellsInRow',
  MODEL_TILES: 'game.model.tiles',
  MODEL_SHOULD_ADD_TILE: 'game.model.shouldAddTile',
  MODEL_SCORE: 'game.model.score',
};

export default MODELS;
