import { connect } from 'react-redux';

import TouchScreenToolbar from './TouchScreenToolbar';
import { acRedoAction, acUndoAction } from '../../../../../../store/actions';
import { getIsCurrentGameCompleted } from '../../../../../../store/selectors';

const mapStateToProps = state => ({
  isGameCompleted: getIsCurrentGameCompleted(state),
});

const mapDispatchToProps = {
  redo: acRedoAction,
  undo: acUndoAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(TouchScreenToolbar);
