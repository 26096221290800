import { getLast } from '../../core/utils/common-utils';
import Tile from '../../components/domain/Tile/Tile';
import getTileSize from './get-tile-size';
import { getIsCurrentGameCompleted } from '../../store/selectors';

const undoAction = state => {
  if (getIsCurrentGameCompleted({ game: { model: state } })) {
    return state;
  }

  const { actions, canceledActions, cellsInRow } = state;
  const size = getTileSize(cellsInRow);

  if (actions.length < 2) {
    return state;
  }

  const canceled = getLast(actions);
  const newActions = actions.slice(0, actions.length - 1);
  const lastAction = getLast(newActions);

  return {
    ...state,
    actions: newActions,
    canceledActions: canceledActions.concat([canceled]),
    score: lastAction.score,
    tiles: lastAction.tiles.map(props => new Tile({ ...props, size })),
  };
};

export default undoAction;
