import React from 'react';

import Modal from '../../../core/components/Modal';
import GameLayoutForm from './shared/GameLayoutForm';
import { MODALS_ID } from '../../../constants';

import styles from './GameLayout.module.scss';

const GameLayout = () => {
  return (
    <Modal
      id={MODALS_ID.GAME_LAYOUT}
      className={styles['game-layout']}
      closeOnClick
      closeOnEscape
      isCentered
    >
      <GameLayoutForm />
    </Modal>
  );
};

export default GameLayout;
