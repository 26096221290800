const API = '/api/game-2048/v1';

const URLS = {
  SAVE_GAME: `${API}/game`,
  QUICK_SAVE: `${API}/game/quick-save`,
  QUICK_LOAD: `${API}/game/quick-load?size={cellsInRow}`,
  GAME_RANK: `${API}/game/rank`,
  GET_TOP_GAMES: `${API}/games/top?size={cellsInRow}&count={count}`,
};

export default URLS;
