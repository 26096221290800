import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import Header from './shared/Header';
import Content from './shared/Content';

import styles from './Page.module.scss';
import setPageTitle from '../../utils/set-page-title';

const Page = ({ id, pageTitle, title, acSetLocation }) => {
  const location = useLocation();

  useEffect(() => {
    setPageTitle(pageTitle);
    acSetLocation(location);
  }, [location, acSetLocation, pageTitle]);

  return (
    <div className={styles['page']}>
      <Header title={title} />
      <Content pageId={id} />
    </div>
  );
};

Page.propTypes = {
  id: PropTypes.string.isRequired,
  pageTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  acSetLocation: PropTypes.func.isRequired,
};

export default Page;
