import { post, parseResponse, get } from '../core/utils/network-requests';

import { URLS } from '../constants';

const saveGame = async (data, sessionToken) => {
  const url = URLS.SAVE_GAME;

  const response = await post({
    url,
    headers: {
      'session-token': sessionToken,
      'Content-Type': 'application/json',
    },
    body: data,
  });

  const parsedResponse = await parseResponse(response);

  if (!response.ok) {
    throw parsedResponse;
  }

  return parsedResponse;
};

const quickSave = async (data, sessionToken) => {
  const url = URLS.QUICK_SAVE;

  const response = await post({
    url,
    headers: {
      'session-token': sessionToken,
      'Content-Type': 'application/json',
    },
    body: data,
  });

  const parsedResponse = await parseResponse(response);

  if (!response.ok) {
    throw parsedResponse;
  }

  return parsedResponse;
};

const quickLoad = async (cellsInRow, sessionToken) => {
  const url = URLS.QUICK_LOAD.replace('{cellsInRow}', cellsInRow);

  const response = await get({
    url,
    headers: {
      'session-token': sessionToken,
    },
  });

  const parsedResponse = await parseResponse(response);

  if (!response.ok) {
    throw parsedResponse;
  }

  return parsedResponse;
};

const gamesApi = { saveGame, quickSave, quickLoad };

export default gamesApi;
