import { randomFromRange } from '../../core/utils/common-utils';
import Tile from '../../components/domain/Tile/Tile';
import getTileSize from './get-tile-size';

const createTile = state => {
  const { actions, cellsInRow, score, tiles } = state;
  const maxLength = cellsInRow ** 2;
  const newTilesCount = cellsInRow > 3 ? cellsInRow - 3 : 1;
  if (tiles.length >= maxLength) {
    return state;
  }
  let newTiles = tiles.slice();
  for (let i = 0; i < newTilesCount; i++) {
    const matrix = new Array(cellsInRow ** 2)
      .fill({ x: 0, y: 0, value: 0 })
      // eslint-disable-next-line no-loop-func
      .map((item, j) => {
        const y = Math.floor(j / cellsInRow);
        const x = j - y * cellsInRow;
        const tile = newTiles.find(
          ({ props: { x: tileX, y: tileY } }) => x === tileX && y === tileY,
        );

        return {
          x,
          y,
          value: tile?.props.value || 0,
        };
      })
      .filter(item => item.value === 0);

    const matrixIndex = randomFromRange(0, matrix.length - 1);
    const tileProps = {
      ...matrix[matrixIndex],
      value: randomFromRange(1, 2) * 2,
      size: getTileSize(cellsInRow),
    };
    newTiles = [...newTiles, new Tile(tileProps)];
    if (newTiles.length >= maxLength) {
      break;
    }
  }
  const newActions = [
    ...actions,
    {
      score,
      tiles: newTiles.map(({ props: { x, y, targetValue } }) => ({
        x,
        y,
        value: targetValue,
      })),
    },
  ];

  return {
    ...state,
    actions: newActions,
    canceledActions: [],
    tiles: newTiles,
    shouldAddTile: false,
  };
};

export default createTile;
