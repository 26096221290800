import { connect } from 'react-redux';

import MatchMediaService from './MatchMediaService';
import { acSetMedia } from '../../store/actions';

const mapDispatchToProps = {
  acSetMedia,
};

export default connect(null, mapDispatchToProps)(MatchMediaService);
