import { connect } from 'react-redux';

import GameIsOverForm from './GameIsOverForm';
import { getScore, getTilesStats } from '../../../../../store/selectors';
import { acRemoveLastModal } from '../../../../../core/store/actions';
import { getRank } from '../../../../../store/actions';
import { getIsLogged } from '../../../../../core/store/selectors';

const mapStateToProps = state => ({
  isLogged: getIsLogged(state),
  score: getScore(state),
  tileStats: getTilesStats(state),
});

const mapDispatchToProps = {
  closeModal: acRemoveLastModal,
  getRank,
};

export default connect(mapStateToProps, mapDispatchToProps)(GameIsOverForm);
